/*global jQuery, ziCookieTest*/
(function($) {

  'use strict';

  var config = window.CookieConfig,
    cookieName = 'CookieAccept-' + config.domain,
    boxId = 'CookieBox',
    writeCookie = function(name, value, days) {
      var date,
        expires,
        domain;
      if (days) {
        date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = '; expires=' + date.toGMTString();
      } else {
        expires = '';
      }
      domain = config.domain ? '; domain=' + config.domain : '';
      document.cookie = name + '=' + value + expires + domain + '; path=/';
    },
    readCookie = function(name) {

      var nameEQ = name + '=';
      var ca = document.cookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
          c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) === 0) {
          return c.substring(nameEQ.length, c.length);
        }
      }
      return null;
    },
    createBox = function() {
      var $wrapper = $('<div>').attr('id', boxId),
        $text = $('<div>').html(config.message),
        $link = $('<a>').attr('href', config.linkUrl).attr('target', '_blank')
          .html(config.linkText),
        $accept = $('<div>').html(config.acceptText),
        cssBox = {
          position: 'fixed',
          left: 0,
          lineHeight: '30px',
          backgroundColor: config.boxBackground,
          color: config.boxColor,
          width: '100%',
          textAlign: 'center',
          padding: '10px',
          zIndex: '10000'
        },
        cssBoxMobile = {
          position: 'relative',
          lineHeight: '30px',
          backgroundColor: config.boxBackground,
          color: config.boxColor,
          width: '100%',
          textAlign: 'center',
          padding: '10px',
          zIndex: '10000'
        },
        cssAccept = {
          borderRadius: '0px',
          border: '0px',
          padding: '6px 10px',
          fontWeight: 'normal',
          cursor: 'pointer',
          margin: '0px 10px 0px 30px',
          webkitTransition: '0.25s',
          transition: '0.25s',
          display: 'inline',
          textShadow: 'rgb(0, 0, 0) 0px 0px 0px',
          whiteSpace: 'nowrap',
          backgroundColor: config.acceptBackground,
          color: config.acceptColor
        },
        cssLink = {
          color: '#FFF',
          marginLeft: '10px'
        };
      cssBox[config.boxPosition] = 0;

      $accept.click(function() {
        writeCookie(cookieName, 'accepted', config.days);
        $wrapper.fadeOut();
      });

      $text.append($link);
      $text.append($accept);
      $wrapper.append($text);
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        $wrapper.css(cssBoxMobile);
      } else {
        $wrapper.css(cssBox);
      }
      $accept.css(cssAccept);
      $link.css(cssLink);
      return $wrapper;
    },
    showBox = function() {
      var $box;
      if (readCookie(cookieName) === 'accepted' &&
          typeof ziCookieTest === 'undefined') {
        return true;
      }
      writeCookie(cookieName, 'visited', config.days);
      $box = createBox();
      $('body').prepend($box);
    },
    addJQuery = function(callback) {
      var googleJQuery = document.createElement('script');
      googleJQuery.setAttribute('type', 'text/javascript');
      googleJQuery.setAttribute(
        'src',
        ('https:' === document.location.protocol ? 'https://' : 'http://') +
          'ajax.googleapis.com/ajax/libs/jquery/1.8.1/jquery.min.js'
      );
      if (callback) {
        googleJQuery.onload = googleJQuery.onreadystatechange = function() {
          $ = jQuery;
          callback.call();
        };
      }
      document.getElementsByTagName('head')[0].appendChild(googleJQuery);
    };

  if ($) {
    showBox();
  } else {
    addJQuery(showBox);
  }

}(typeof jQuery !== 'undefined' ? jQuery : false));
